.wall {
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  flex: 0 1 50%;
  justify-content: flex-end;
  gap: 30px;
}

.wall-column {
  min-height: unset;
  max-height: unset;
}

.wall-column .wall__item-nft {
  max-width: 315px;
  min-width: 315px;
}

.wall-up,
.wall-down {
  gap: 30px;
}

.nft.wall-up {
  animation: nft-scroll-up 40s -39s linear infinite;
  -webkit-animation: nft-scroll-up 40s -39s linear infinite;
}
.nft.wall-down {
  animation: nft-scroll-down 40s -25s linear infinite;
  -webkit-animation: nft-scroll-down 40s -25s linear infinite;
}

.nft.wall-left {
  animation: ntf-scroll-left 40s -39s linear infinite;
  -webkit-animation: ntf-scroll-left 40s -39s linear infinite;
}
.nft.wall-right {
  animation: ntf-scroll-right 40s -37s linear infinite;
  -webkit-animation: ntf-scroll-right 40s -37s linear infinite;
}

.avatar.wall-up {
  animation: avatar-scroll-up 40s linear infinite;
  -webkit-animation: avatar-scroll-up 40s linear infinite;
}
.avatar.wall-down {
  animation: avatar-scroll-down 40s linear infinite;
  -webkit-animation: avatar-scroll-down 40s linear infinite;
}

.wall-left,
.wall-right {
  gap: 30px;
}

@keyframes ntf-scroll-left {
  100% {
    transform: translateX(-1725px);
    -webkit-transform: translateX(-1725px);
    -moz-transform: translateX(-1725px);
    -ms-transform: translateX(-1725px);
    -o-transform: translateX(-1725px);
  }
}

@keyframes ntf-scroll-right {
  0% {
    transform: translateX(-1725px);
    -webkit-transform: translateX(-1725px);
    -moz-transform: translateX(-1725px);
    -ms-transform: translateX(-1725px);
    -o-transform: translateX(-1725px);
  } 100% {
  transform: translateX(0);
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
}
}

@keyframes avatar-scroll-up {
  100% {
    transform: translateY(-2120px);
    -webkit-transform: translateY(-2120px);
    -moz-transform: translateY(-2120px);
    -ms-transform: translateY(-2120px);
    -o-transform: translateY(-2120px);
  }
}
@keyframes avatar-scroll-down {
  0% {
    transform: translateY(calc(-2120px));
    -webkit-transform: translateY(calc(-2120px));
    -moz-transform: translateY(calc(-2120px));
    -ms-transform: translateY(calc(-2120px));
    -o-transform: translateY(calc(-2120px));
  }
  100% {
    transform: translateY(calc(0));
    -webkit-transform: translateY(calc(0));
    -moz-transform: translateY(calc(0));
    -ms-transform: translateY(calc(0));
    -o-transform: translateY(calc(0));
  }
}

@keyframes nft-scroll-up {
  100% {
    transform: translateY(-2150px);
    -webkit-transform: translateY(-2150px);
    -moz-transform: translateY(-2150px);
    -ms-transform: translateY(-2150px);
    -o-transform: translateY(-2150px);
  }
}
@keyframes nft-scroll-down {
  0% {
    transform: translateY(calc(-2150px));
    -webkit-transform: translateY(calc(-2150px));
    -moz-transform: translateY(calc(-2150px));
    -ms-transform: translateY(calc(-2150px));
    -o-transform: translateY(calc(-2150px));
  }
  100% {
    transform: translateY(calc(0));
    -webkit-transform: translateY(calc(0));
    -moz-transform: translateY(calc(0));
    -ms-transform: translateY(calc(0));
    -o-transform: translateY(calc(0));
  }
}
