.about-us {
  align-items: flex-start;
  gap: 60px;
}

.about-us__hero-section__title {
  margin-bottom: 30px;
}

.about-us__hero-section--align-center {
  align-items: center;
  gap: 12px;
}

.about-us__description-text {
  max-width: 645px !important;
}


@media (max-height: 850px) {
  .about-us__icon {
    max-height: 180px;
  }
}
