.contact-us__container {
  padding: 20px 30px;
  min-height: 100vh;
  gap: 30px;
}

.contact-us {
  margin-bottom: auto;
}

.contact-us--gap {
  gap: 30px;
}

.contact-us__content {
  flex: 1 1 calc(48% -30px);
}

.contact-us__form-container {
  flex: 1 0 47%;
  align-self: flex-start;
  position: relative;
}

.contact-us__title {
  gap: 40px;
  font-weight: 700;
  font-size: 5.625rem;
  margin-bottom: 30px;
}

.contact-us__title-icon {
  margin: auto 0;
}

.contact-us__description {
  line-height: 1.5rem;
  font-size: 1rem;
  margin-bottom: 50px;
}

.contact-us__information-body {
  gap: 20px;
}

.contact-us__information-title {
  margin-bottom: 10px;
  font-size: 1rem;
  font-weight: 700;
}

.contact-us__information--wrap {
  flex: wrap;
  gap: 50px;
}

.contact-us__form-background {
  position: absolute;
  right: -10px;
  bottom: -10px;
  width: 100%;
  height: 100%;
  border: 3px solid #000;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.contact-us__form {
  position: relative;
  z-index: 1;
  background-color: #fff;
  border: 3px solid #000;
  padding: 35px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.contact-us__form__title {
  font-weight: 700;
  font-size: 1.875rem;
  margin-bottom: 40px;
}

.contact-us__form--wrap {
  margin-bottom: 30px;
  gap: 30px;
}

.contact-us__input-container {
  border: 3px solid #000;
  border-radius: 10px;
  align-items: center;

  font-size: 1rem;
  font-weight: 700;
}

.input {
  font-size: 1rem;
  font-weight: 700;
  width: 100%;
  padding: 20px;
  border: 3px solid #000;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.contact-us__input-label {
  flex: 1 0 45%;
  height: 100%;
  background-color: #fff;
  padding-left: 15px;
  display: flex;
  align-items: center;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.contact-us__input {
  border: none;
  font-size: 1rem;
  font-weight: 700;
  padding: 20px 10px 20px 5px;
  height: 100%;
  flex: 1 1 50%;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.contact-us__textarea-container {
  border-radius: 10px;
  position: relative;
  margin-bottom: 30px;
}

.contact-us__textarea {
  position: relative;
  resize: vertical;
  overflow-x: hidden;
}

.contact-us__textarea-label {
  display: none;
  position: absolute;
  left: 20px;
  top: 20px;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1rem;
}

.contact-us__button {
  color: #fff;
  display: inline-flex;
  gap: 10px;
  font-weight: 700;
  align-items: center;
  white-space: nowrap;
  border-radius: 10px;
  padding: 15px 30px;
  background-color: #d24f30;
  text-transform: lowercase;
  font-size: 1rem;
  box-shadow: 5px 5px 0 0px #000;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.contact-us__button:after {
  content: url("../../_assets/Arrow.svg");
  margin-bottom: -5px;
}

.contact-us__actions {
  align-items: center;
  gap: 30px;
}

.contact-us__check-container {
  gap: 10px;
  flex: 1 1 auto;
}

.contact-us__checkbox {
  accent-color: #000;
  width: 25px;
  padding: 4px;
  border: 3px solid #000;
  aspect-ratio: 1;
  cursor: pointer;
}

.contact-us__check-label {
  font-size: 0.875rem;
  line-height: 1.5;
  cursor: pointer;
}

.contact-us__footer {
  font-size: 1rem;
  font-weight: 700;
}

.contact-us__footer__container {
  justify-content: space-between;
}
.contact-us__footer--gap {
  gap: 40px;
}

.nav-link {
  position: relative;
  text-transform: lowercase;
}

.nav-link:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -5px;
  height: 2px;
  width: 0;
  background: #000;
  transition: 0.6s width ease;
  -webkit-transition: 0.6s width ease;
  -moz-transition: 0.6s width ease;
  -ms-transition: 0.6s width ease;
  -o-transition: 0.6s width ease;
}

.nav-link:hover:before {
  width: 100%;
}
