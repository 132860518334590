#arrow-button {
  width: 180px;
  height: 66px;
}
#arrow {
  transition: transform ease 0.3s;
  -webkit-transition: transform ease 0.3s;
  -moz-transition: transform ease 0.3s;
  -ms-transition: transform ease 0.3s;
  -o-transition: transform ease 0.3s;
}

#arrow-button:hover #arrow {
  transform: translate(50px);
  -webkit-transform: translate(50px);
  -moz-transform: translate(50px);
  -ms-transform: translate(50px);
  -o-transform: translate(50px);
}
