.services {
  padding: 30px 0px 120px 0px;
}

.services--gap {
  gap: 45px;
}

.services__main-content-container {
  margin-bottom: 120px;
  align-items: center;
}

.services__title {
  font-size: 5.625rem;
  font-weight: 700;
  margin-bottom: 30px;
}

.services__title--gap {
  gap: 20px;
  align-items: center;
}

.services__content-description {
  font-size: 1.313rem;
  line-height: 28px;
  max-width: 645px;
  margin-bottom: 40px;
}

.services__header-cta {
  padding: 15px 30px;
  display: inline-block;
  background: #d24f30;
  border-radius: 8px;
  font-size: 1.125rem;
  color: #fff;
  box-shadow: 5px 5px 0 0 #000;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.services__illustration {
  position: relative;
  flex: 1 1 auto;
}

.services__illustration-image {
  max-width: 100%;
}

.top-setting {
  position: absolute;
  top: 5px;
  right: 68px;
  animation: rotation1 6s linear infinite;
  -webkit-animation: rotation1 6s linear infinite;
}

.bottom-setting {
  position: absolute;
  top: 95px;
  right: 22px;
  animation: rotation2 6s linear infinite;
  -webkit-animation: rotation2 6s linear infinite;
}

.includes__title {
  font-size: 4.375rem;
  font-weight: 700;
  margin-bottom: 50px;
  text-align: center;
}

.includes--gap {
  gap: 30px;
}

.our-works {
  padding-bottom: 120px;
}

.our-works__title {
  text-align: center;
  font-size: 4.375rem;
  font-weight: 700;
  margin-bottom: 50px;
}

.our-works__wall-cotnainer {
  margin-bottom: 50px;
}

.our-works__link-container {
  text-align: center;
}

.our-works__link {
  color: #fff;
  display: inline-flex;
  gap: 10px;
  font-weight: 700;
  align-items: center;
  white-space: nowrap;
  border-radius: 10px;
  padding: 15px 30px;
  background-color: #d24f30;
  text-transform: lowercase;
  font-size: 1rem;
  box-shadow: 4px 5px 0 0px #000;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.our-works__link:after {
  content: url("../../_assets/Arrow.svg");
  margin-bottom: -3px;
}

@keyframes rotation1 {
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
  }
}
@keyframes rotation2 {
  100% {
    transform: rotate(-360deg);
    -webkit-transform: rotate(-360deg);
    -moz-transform: rotate(-360deg);
    -ms-transform: rotate(-360deg);
    -o-transform: rotate(-360deg);
  }
}
