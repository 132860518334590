.services__item-body {
  padding: 30px;
  border: 3px solid #000;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.services__item-icon-container {
  margin-bottom: 20px;
}

.services__item-label {
  font-size: 1.563rem;
  font-weight: 700;
  margin-bottom: 15px;
}

.services__item-description {
  font-size: 1rem;
  line-height: 1.5rem;
}
