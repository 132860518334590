.home-page {
  overflow: hidden;
  align-items: flex-start;
  gap: 60px;
}

.content {
  min-height: 100vh;
  flex: 0 1 50%;
  padding: 30px 0px;
}

/* Main Content */

.content__main {
  position: relative;
}

.content__main:before {
  content: "";
  position: absolute;
  background: #e4753270;
  width: 280px;
  aspect-ratio: 1;
  left: 0px;
  top: 40%;
  filter: blur(150px);
  -webkit-filter: blur(150px);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.content__main:after {
  content: "";
  position: absolute;
  background: #e4dd3260;
  width: 280px;
  aspect-ratio: 1;
  left: 350px;
  top: 7%;
  filter: blur(120px);
  -webkit-filter: blur(120px);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

/* Hero section */

.hero-section {
  margin-bottom: 67px;
}

.hero-section__title {
  margin-bottom: 30px;
}

.hero-section__title--gap {
  gap: 10px;
}

.title-word__icon {
  gap: 60px;
  align-items: center;
}

.title-word {
  font-size: 5.625rem;
  font-weight: 700;
}

.hero-section__description-link {
  display: block;
  height: auto;
}

.hero-section__description--gap {
  gap: 60px;
}

.hero-section__description-text {
  max-width: 340px;
  font-size: 1.313rem;
  line-height: 1.75rem;
}

@media (max-height: 850px) {
  .header {
    margin-bottom: 40px;
  }
  .title-word {
    font-size: 4.5rem;
  }
  .hero-section {
    margin-bottom: 20px;
  }
  .hero-section__title {
    margin-bottom: 20px;
  }
  .content {
    padding: 30px 0px 0px 0px;
  }
  .hero-section__description-text {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
}
