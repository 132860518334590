* {
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
}

*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

:focus,
:active {
  outline: none;
}
a:focus,
a:active {
  outline: none;
}

nav,
footer,
header,
aside {
  display: block;
}

html,
body {
  width: 100%;
  min-height: 100vh;
  font-size: 100%;
  line-height: 1;
  font-size: 16px;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

input,
button,
textarea {
  font-family: inherit;
}

input[type="button"] {
  cursor: pointer;
}
input::-ms-clear {
  display: none;
}
button {
  cursor: pointer;
  background: transparent;
}
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
a {
  text-decoration: none;
  color: #111;
} /*<<<Color*/
a:hover {
  text-decoration: none;
}
ul li {
  list-style: none;
}
img {
  vertical-align: top;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: 400;
}
/*Конец обнуления*/

body {
  margin: 0;
  font-family: "Raleway", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

.wrapper {
  height: 100%;
  width: 100%;
}

.container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 30px;
}

.row {
  display: flex;
}

.column {
  display: flex;
  flex-direction: column;
}

.animated-button {}